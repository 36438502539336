<template>
  <div>
    <popup-remove
      title="ผู้ใช้งานระบบ"
      url="/Member/Remove"
      column="member_id"
      :remove-id="dataPopup.member_id"
      :remove-name="`${dataPopup.name}  ${dataPopup.surname}`"
      @fetch-data="fncFetchDataTable"
    />
    <popup-detail :data="dataPopupDetail" />
    <div class="mb-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <button-add url="member/form" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <per-page-option
            :per-page.sync="perPage"
          />
        </b-col>
      </b-row>
    </div>
    <b-card no-body>
      <input-search :search.sync="searchQuery" />
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
        show-detail
      />
      <table-simple
        :overlay-load-data-table="overlayLoadDataTable"
        :table-title="tableTitle"
        :total-data="dataTable.length"
      >
        <template slot="data">
          <b-tr
            v-for="(item, index) in dataTable"
            :key="index"
          >
            <b-td>
              {{ item.order }}
            </b-td>
            <b-td>
              {{ `${item.name}  ${item.surname}` }}
            </b-td>
            <b-td>
              {{ item.member_no }}
            </b-td>
            <b-td>
              {{ item.department_desc ? item.department_desc : '-' }}
            </b-td>
            <b-td>
              {{ item.position_desc ? item.position_desc : '-' }}
            </b-td>
            <b-td>
              {{ item.membertype_desc }}
            </b-td>
            <b-td>
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-detail
                  @click="dataPopup = item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">รายละเอียด</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'member-form-edit', params: { id: item.member_id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">แก้ไข</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-remove
                  @click="dataPopup = item"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">ลบข้อมูล</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </template>
      </table-simple>
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BTr, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ButtonAdd from '../components/ButtonAdd.vue'
import PerPageOption from '../components/PerPageOption.vue'
import InputSearch from '../components/InputSearch.vue'
import TableSimple from '../components/TableSimple.vue'
import PopupRemove from '../components/PopupRemove.vue'
import PopupDetail from '../components/PopupDetail.vue'

import useMember from './useMember'
import Pagination from '../components/Pagination.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BTr,
    BTd,
    ButtonAdd,
    PerPageOption,
    InputSearch,
    TableSimple,
    PopupRemove,
    PopupDetail,
    Pagination,
  },

  directives: {
    Ripple,
  },

  setup() {
    // variable from inport
    const {
      // variable input
      search,

      // variable table
      currentPage,
      totalData,
      perPage,
      perPageOption,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    } = useMember()

    return {
      // variable input
      search,

      // variable table
      currentPage,
      totalData,
      perPage,
      perPageOption,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    }
  },
}
</script>

<style scoped>
.table thead th, .table tfoot th {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.94rem;
    font-weight: bold;;
    letter-spacing: 0.5px;
    height: 40px;
}

.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
