import {
  ref, watch, onMounted, computed,
} from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMember() {
  // Notification
  const toast = useToast()

  // variable input
  const search = ref(null)

  // variable table
  const currentPage = ref(1)
  const totalData = ref(1)
  const perPage = ref(10)
  const perPageOption = [10, 25, 50, 100]
  const tableTitle = [
    { title: '#', style: 'min-width: 65px; width:65px;' },
    { title: 'ชื่อ - สกุล', style: 'min-width: 150px;' },
    { title: 'บัญชีผู้ใช้', style: 'min-width: 220px; width:220px;' },
    { title: 'แผนก', style: 'min-width: 220px; width:220px;' },
    { title: 'ตำแหน่ง', style: 'min-width: 220px; width:220px;' },
    { title: 'ประเภทผู้ใช้', style: 'min-width: 220px; width:220px;' },
    { title: 'เครื่องมือ', style: 'min-width: 80px; width:80px;' },
  ]
  const searchQuery = ref(null)
  const dataTable = ref([])
  const itemLocalTo = ref(0)
  const itemLocalCount = ref(0)
  const dataPopup = ref({})

  // variable overlay
  const overlayLoadDataTable = ref(false)

  // variable computed
  const dataPopupDetail = computed(() => {
    if (!Object.keys(dataPopup.value).length) return []

    return [
      { title: 'เพศ', value: dataPopup.value.sex === 'M' ? 'ชาย' : 'หญิง' },
      { title: 'ชื่อ', value: dataPopup.value.name },
      { title: 'นามสกุล', value: dataPopup.value.surname },
      { title: 'ชื่อ (ภาษาอังกฤษ)', value: dataPopup.value.nameEng ? dataPopup.value.nameEng : '-' },
      { title: 'นามสกุล (ภาษาอังกฤษ)', value: dataPopup.value.surnameEng ? dataPopup.value.surnameEng : '-' },
      { title: 'วันเกิด', value: dataPopup.value.birth_date ? dataPopup.value.birth_date : '-' },
      { title: 'เบอร์โทร', value: dataPopup.value.tel ? dataPopup.value.tel : '-' },
      { title: 'อีเมล', value: dataPopup.value.email ? dataPopup.value.email : '-' },
      { title: 'ที่อยู่', value: dataPopup.value.address ? dataPopup.value.address : '-' },
      { title: 'แผนก', value: dataPopup.value.department_desc ? dataPopup.value.department_desc : '-' },
      { title: 'ตำแหน่ง', value: dataPopup.value.position_desc ? dataPopup.value.position_desc : '-' },
      { title: 'ประเภทผู้ใช้', value: dataPopup.value.membertype_desc ? dataPopup.value.membertype_desc : '-' },
      { title: 'Username', value: dataPopup.value.member_no ? dataPopup.value.member_no : '-' },
      { title: 'create_date', value: dataPopup.value.create_date },
      { title: 'create_user', value: dataPopup.value.create_user },
      { title: 'update_date', value: dataPopup.value.update_date },
      { title: 'update_user', value: dataPopup.value.update_user },
    ]
  })

  const fncFetchDataTable = () => {
    overlayLoadDataTable.value = true

    const payload = {
      search: searchQuery.value,
      per_page: perPage.value,
      page: currentPage.value,
    }

    axios
      .get('/Member', { params: payload })
      .then(response => {
        // console.log('fncFetchDataTable', response.data.value.data)
        const setDataTable = []
        Object.keys(response.data.value.data).forEach(key => {
          const item = response.data.value.data[key]
          setDataTable[key] = item
          setDataTable[key].order = response.data.value.total - key - ((currentPage.value - 1) * perPage.value)
        })
        dataTable.value = setDataTable
        totalData.value = response.data.value.total

        itemLocalTo.value = totalData.value - ((currentPage.value - 1) * perPage.value)
        itemLocalCount.value = totalData.value !== 0 ? itemLocalTo.value - dataTable.value.length + 1 : 0

        overlayLoadDataTable.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadDataTable.value = false
      })
  }

  onMounted(() => {
    fncFetchDataTable()
  })

  watch([searchQuery, currentPage, perPage], () => {
    fncFetchDataTable()
  })

  return {
    // variable input
    search,

    // variable table
    currentPage,
    totalData,
    perPage,
    perPageOption,
    tableTitle,
    dataTable,
    searchQuery,
    itemLocalTo,
    itemLocalCount,
    dataPopup,

    // variable overlay
    overlayLoadDataTable,

    // variable computed
    dataPopupDetail,

    // function
    fncFetchDataTable,
  }
}
